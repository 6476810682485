<template>
    <BaseModal
        :class="$style.RegisterSuccess"
        login
        :visible="visible"
        @after-enter="$emit('after-enter')"
        @after-leave="$emit('after-leave')"
        @close="$emit('close')"
    >
        <div :class="$style.title">
            {{ $t('registration') }}
        </div>
        <div :class="$style.success">
            {{ $t('registration is almost complete') }}
            {{ $t('we have sent you an email to') }} {{ data.email }}. {{
                $t('please follow the link from the email to confirm registration')
            }}
        </div>
        <div :class="$style.button">
            <UiVButton
                size="large"
                color="primary"
                responsive
                @click="emit('close')"
            >
                {{ $t('well') }}
            </UiVButton>
        </div>
        <div :class="$style.copy">
            © 2022 swop.is
        </div>
    </BaseModal>
</template>

<script setup>
import BaseModal from './BaseModal.vue';
// const { $modal } = useNuxtApp();
// function clickOnTest() {
//     $modal.open({ component: markRaw(PartnersModal) });
// }
const emit = defineEmits(['close', 'after-enter', 'after-leave']);

defineProps({
    data: {
        type: Object,
        required: true,
    },
    visible: {
        type: Boolean,
        default: false,
    },
});
</script>

<style lang="scss" module>
.RegisterSuccess {
    //
}

.title {
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    text-align: center;
    color: $base-100;
}

.success {
    font-weight: 400;
    font-size: 16px;
    line-height: 136.99%;
    /* or 22px */

    text-align: center;

    /* dsgn_text */

    color: #A7A7A7;
    margin-top: 19px;
}

.input {
    &:not(:last-child) {
        margin-bottom: 13px;

        @include respond-to(xs) {
            margin-bottom: 11px;
        }
    }
}

.bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 19px;
}

.check {
    display: flex;
    align-items: center;
}

.text {
    margin-left: 8px;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    color: #A7A7A7;
}

.forgot {
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    text-decoration-line: underline;
    cursor: pointer;

    &:hover {
        color: $base-100;
    }
}

.button {
    margin-top: 25px;
}

.register {
    margin-top: 18px;
    font-size: 16px;
    line-height: 18px;
    text-align: center;

    /* dsgn_text */

    color: #A7A7A7;
}

.copy {
    margin-top: 3rem;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    text-align: center;

    /* dsgn_text */

    color: #A7A7A7;
}
</style>
