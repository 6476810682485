import * as Sentry from '@sentry/vue';

interface SentryConfig {
  dsn: string;
  environment: string;
}

export default defineNuxtPlugin((nuxtApp) => {
  const router = useRouter();
  const config = useRuntimeConfig();

  const sentry = config.public.sentry as SentryConfig;

  if (!sentry.dsn) {
    return;
  }

  Sentry.init({
    app: nuxtApp.vueApp,
    dsn: sentry.dsn,
    environment: sentry.environment,
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],

    tracesSampleRate: 0.2,

    replaysSessionSampleRate: 0.0,
    replaysOnErrorSampleRate: 1.0,

    tracePropagationTargets: ['localhost', 'https://swop.is'],
  });
});
