import { UseFetchOptions } from 'nuxt/app';
import { defu } from 'defu';
import { NitroFetchOptions } from 'nitropack';
import { useUserStore } from '~/stores/useUserStore';

function getApiFetchDefaultOptions<T>(): UseFetchOptions<T> {
  const config = useRuntimeConfig();
  return {
    baseURL: config.public.apiBase,
    onRequest({ options }) {
      const { token } = useUserStore();
      if (token) {
        options.headers = {
          ...options.headers,
          Authorization: `Bearer ${token}`,
        };
      }
    },
    async onResponse(context) {
      if (
        context.response.status === 401 &&
        context.response._data?.code === 'token_not_valid'
      ) {
        if (context.response.url.includes('/auth/refresh')) {
          useUserStore().logout();
        } else {
          try {
            await useUserStore().revalidateToken();
            if (!useUserStore().token) {
              throw new Error('Token not valid');
            }
            context.options.headers = {
              ...context.options.headers,
              Authorization: `Bearer ${useUserStore().token}`,
            };
            context.response = await $fetch.raw(
              context.request,
              context.options
            );
          } catch (e: any) {
            useUserStore().logout();
          }
        }
      }
    },
  };
}

export function useApiFetch<T>(url: string, options: UseFetchOptions<T> = {}) {
  const actualUrl = localeUrl(url.toString());
  const defaults = getApiFetchDefaultOptions<T>();
  const params = defu(options, defaults);

  return useFetch(actualUrl, params);
}

export function apiFetch<T>(url: string, options: NitroFetchOptions<any> = {}) {
  const actualUrl = localeUrl(url.toString());
  const defaults = getApiFetchDefaultOptions<T>();
  const params = defu(options, defaults);

  return $fetch(actualUrl, params) as Promise<T>;
}
