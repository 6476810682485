import type {ExtractPropTypes} from 'vue';

export const baseInputProps = {
    hint: {
        type: String,
        default: '',
    },
    error: {
        type: String,
        default: '',
    },
    label: {
        type: String,
        default: '',
    },
    prependIcon: {
        type: Object,
        default: null,
    },
    appendIcon: {
        type: Object,
        default: null,
    },
    prependInnerIcon: {
        type: Object,
        default: null,
    },
    appendInnerIcon: {
        type: Object,
        default: null,
    },
    color: {
        type: String,
        default: 'primary',
    },
    size: {
        type: String,
        default: 'medium',
    },
    disabled: Boolean,
    readonly: Boolean,
    loading: Boolean,
    mask: {
        type: String,
        default: '',
    },
};

export type BaseInputProps = ExtractPropTypes<typeof baseInputProps>;
