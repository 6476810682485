<template>
    <component
        :is="component"
        v-bind="linkProperties"
        class="v-button"
        :class="classes"
        :disabled="disabled"
        :type="!link && !href ? type : null"
        @click="onClick"
    >
        <span
            v-if="icon"
            class="v-button__icon"
        >
            <slot name="icon"></slot>
        </span>

        <span
            v-if="$slots.default"
            class="v-button__text"
        >
            <slot></slot>
        </span>

        <span v-if="iconRight" class="v-button__icon-right">
            <slot name="icon-right"></slot>
        </span>
    </component>
</template>

<script>
export default {
    name: 'VButton',

    props: {
        /**
         * Определяет классы, которые будут модифицировать размер
         */
        size: {
            type: String,
            default: 'medium',
            validator(value) {
                return ['large', 'medium', 'small', 'custom'].includes(value);
            },
        },

        /**
         * Определяет классы, которые будут модифицировать цвет
         */
        color: {
            type: String,
            default: 'primary',
        },

        /**
         * Очевидно, что это свойство задизейблет кнопку
         */
        disabled: {
            type: Boolean,
            default: false,
        },

        /**
         * Растягивает кнопку на всю ширину внешнего контейнера
         */
        responsive: {
            type: Boolean,
            default: false,
        },

        /**
         * Меняет тип кнопки
         */
        type: {
            type: String,
            default: 'button',
        },

        /**
         * Устанавливает тег a для кнопки
         */
        href: {
            type: String,
            default: '',
        },

        /**
         * target="_blank" для ссылок
         */
        blank: {
            type: Boolean,
            default: false,
        },

        /**
         * Для внутренних ссылок (тег n-link)
         */
        link: {
            type: [String, Object],
            default: '',
        },

        disabledHover: {
            type: Boolean,
            default: false,
        },

        relative: {
            type: Boolean,
            default: false,
        },

        loading: {
            type: Boolean,
            default: false,
        },

        icon: {
            type: Boolean,
            default: false,
        },

        iconRight: {
            type: Boolean,
            default: false,
        },

        spinner: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['click', 'clickBtn'],

    computed: {
        component() {
            if (this.href) {
                return 'a';
            } else if (this.link) {
                return defineNuxtLink({});
            }
            return 'button';
        },

        linkProperties() {
            const linkProperties = {};
            if (this.link) {
                linkProperties.to = this.link;
            } else if (this.href) {
                linkProperties.href = this.href;
            }

            if (this.blank) {
                linkProperties.target = '_blank';
            }
            return linkProperties;
        },

        classes() {
            return [
                `v-button--${this.color}`,
                `v-button--${this.size}`,
                {
                    'is-icon': this.icon || this.iconRight,
                    'is-disabled': this.disabled,
                    'is-responsive': this.responsive,
                    'is-disabled-hover': this.disabledHover,
                    'is-relative': this.relative,
                    'is-loading': this.loading,
                    'is-spinner': this.spinner,
                },
            ];
        },
    },

    methods: {
        onClick(e) {
            if (this.disabled || this.loading) {
                return;
            }
            this.$emit('click', e);
        },
    },
};
</script>

<style lang="scss">
.v-button {
    $block: &;

    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: none;
    outline: none;
    text-align: center;
    white-space: nowrap;
    font-family: $base-font;
    font-weight: 400;
    line-height: 1;
    transition: all $base-transition;
    cursor: pointer;
    user-select: none;

    /* Colors */

    &--primary {
        background-color: $brand-green;
        background-image: linear-gradient(106.3deg, #B5D841 29.93%, #D7FF53 73.93%);
        background-size: 150%;
        background-position-x: 100%;
        color: $base-900;
        will-change: background;
        transition-duration: .2s;

        &:not(.is-disabled-hover) {
            @include hover {
                //background-image: linear-gradient(249.02deg, #087ae2 -2.74%, #0c8ac8 9.14%, #20abc2 21.54%, #36cfad 39.1%, #40dc8b 54.08%, #89ee79 73.19%, #97ff57 89.71%);
                background-size: 101%;
            }

            &:active {
                background-color: $brand-green;
                background-image: none;
            }
        }

        &.is-disabled {
            background-image: linear-gradient(106.3deg, #d3f26b 29.93%, #e0efa7 73.93%);
            color: #424141;
            pointer-events: none;
        }
    }

    /* End colors */

    /* Sizes */

    &--large {
        height: 5.1rem;
        padding: 0 7.5rem;
        border-radius: 5rem;
        font-size: 2rem;
        line-height: 2.3rem;

        &.is-icon {
            #{$block}__icon {
                width: 1.3rem;
                height: 1.3rem;
                margin-right: 1.6rem;
            }
        }

        @include respond-to(xs) {
            height: 51px;
            padding: 0 3rem;
            font-size: 20px;
            line-height: 23px;
        }
    }

    &--medium {
        height: 6rem;
        padding: 0 4rem;
        border-radius: 5rem;
        font-size: 16px;
        line-height: 18px;

        //@include respond-to(xs) {
        //    height: 36px;
        //    font-size: 14px;
        //}
        //
        //&.is-background {
        //    &:before {
        //        border-radius: .8rem;
        //    }
        //}
        //
        //&.is-responsive {
        //    padding: 0;
        //}
    }

    &--small {
        height: 3.4rem;
        padding: 0 2.6rem;
        border-radius: 4.8rem;
        font-size: 1.6rem;
        font-weight: 400;
        line-height: 1.8rem;

        //&.is-background {
        //    &:before {
        //        border-radius: .4rem;
        //    }
        //}
        //
        //&.is-responsive {
        //    padding: 0;
        //}
    }

    /* End Sizes */

    /* Modificators */

    &.is-spinner {
        position: relative;

        &:after {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            width: 2rem;
            height: 2rem;
            margin: auto;
            border-radius: 50%;
            border: .3rem solid rgba($base-600, .5);
            border-top-color: $base-1000;
            opacity: 0;
            transition: opacity .3s ease;
            animation: spinner 1s linear 0s infinite;
        }
    }

    &.is-loading {
        pointer-events: none;

        &:after {
            opacity: 1;
        }

        #{$block}__text {
            opacity: 0;
        }
    }

    &.is-responsive {
        width: 100%;
    }

    /* End Modificators */

    &__text {
        //display: flex;
        opacity: 1;
        //transition: opacity $default-transition;
    }

    &__icon {
        display: flex;
        margin-right: .5rem;

        &-right {
            margin-right: unset;
            margin-left: .7rem;
            padding-top: .3rem;
        }
    }

    &__background {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    &__addon {
        display: flex;
        flex-shrink: 0;

        &.is-left {
            margin-right: 4px;
        }

        &.is-right {
            margin-left: 4px;
        }
    }

    @keyframes spinner {
        from {
            transform: rotate(0deg);
        }

        to {
            transform: rotate(360deg);
        }
    }
}
</style>
