<template>
  <BaseModal
    :class="$style.RegisterModal"
    login
    :visible="visible"
    @after-enter="$emit('after-enter')"
    @after-leave="$emit('after-leave')"
    @close="$emit('close')"
  >
    <div :class="$style.title">
      {{ $t('registration') }}
    </div>
    <form
      :class="$style.form"
      @submit.prevent="handleSubmit"
      @keydown.enter.prevent="handleSubmit"
    >
      <div :class="$style.inputs">
        <VInput
          v-model="email"
          placeholder="E-mail"
          type="email"
          :error="errors.email"
          required
          @input="errors.email = ''"
        />
        <InputPassword
          v-model="password"
          :placeholder="$t('password')"
          :error="errors.password"
          required
          @input="errors.password = ''"
        />
        <InputPassword
          v-model="passwordAgain"
          :placeholder="$t('repeat password')"
          :error="errors.passwordAgain"
          required
          @input="errors.passwordAgain = ''"
        />
        <div :class="$style.check">
          <VCheckbox v-model="isMonitoring">
            <div :class="$style.text">
              {{ $t('i am monitoring exchange services') }}
            </div>
          </VCheckbox>
        </div>
      </div>
      <div :class="$style.button">
        <UiVButton
          color="primary"
          responsive
          spinner
          :loading="isLoading"
          type="submit"
        >
          {{ $t('sign up') }}
        </UiVButton>
      </div>
    </form>
    <div :class="$style.copy">© 2023 swop.is</div>
  </BaseModal>
</template>

<script setup>
import BaseModal from './BaseModal.vue';
import RegisterSuccessModal from '~/components/common/RegisterSuccessModal.vue';
import VInput from '~/components/ui/Input/VInput.vue';
import InputPassword from '~/components/common/InputPassword.vue';
import VCheckbox from '~/components/ui/Checkbox/VCheckbox.vue';
import { userApi } from '~/utils/api/user';

const { $modal } = useNuxtApp();

const emit = defineEmits(['close', 'after-enter', 'after-leave']);

const email = ref('');
const password = ref('');
const passwordAgain = ref('');
const isLoading = ref(false);
const errors = ref({});
const isMonitoring = ref(false);

defineProps({
  visible: {
    type: Boolean,
    default: false,
  },
});

function setErrors(error) {
  const obj = {};
  for (const [key, value] of Object.entries(error)) {
    if (Array.isArray(value)) {
      obj[key] = value[0];
    } else {
      obj[key] = value;
    }
  }
  errors.value = { ...obj };
}

async function handleSubmit() {
  if (isLoading.value) {
    return;
  }

  try {
    isLoading.value = true;
    const data = {
      email: email.value,
      password: password.value,
      passwordAgain: passwordAgain.value,
      type: isMonitoring.value ? 'monitoring' : 'regular',
    };
    await userApi.register(data);
    $modal.open(markRaw(RegisterSuccessModal), { email: email.value });
  } catch (error) {
    error?.data
      ? setErrors(error.data)
      : console.warn(
          '[RegisterForm/handleSubmit] POST request failed: ',
          error
        );
  } finally {
    isLoading.value = false;
  }
}
</script>

<i18n lang="json">
{
  "ru": {},
  "en": {}
}
</i18n>

<style lang="scss" module>
.RegisterModal {
  //
}

.title {
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  color: $base-100;
}

.form {
  margin-top: 22px;

  @include respond-to(xs) {
    margin-top: 25px;
  }
}

.input {
  &:not(:last-child) {
    margin-bottom: 13px;

    @include respond-to(xs) {
      margin-bottom: 11px;
    }
  }
}

.bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 19px;
}

.check {
  display: flex;
  align-items: center;
}

.text {
  margin-left: 8px;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: #a7a7a7;
}

.forgot {
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  text-decoration-line: underline;
  cursor: pointer;

  &:hover {
    color: $base-100;
  }
}

.button {
  margin-top: 18px;
}

.register {
  margin-top: 18px;
  font-size: 16px;
  line-height: 18px;
  text-align: center;

  /* dsgn_text */

  color: #a7a7a7;
}

.copy {
  margin-top: 3rem;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  text-align: center;

  /* dsgn_text */

  color: #a7a7a7;
}

.error {
  font-size: 12px;
  line-height: 14px;
  color: red;
}

.inputs {
  display: flex;
  flex-direction: column;
  gap: 1.4rem;
}

.check {
  margin-top: 1.4rem;
}
</style>
