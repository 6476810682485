<script lang="ts" setup>
// import IconsCheck from '~/components/icons/IconsCheck.vue';

const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false,
  },
  checkedValue: {
    type: Boolean,
    default: true,
  },
  uncheckedValue: {
    type: Boolean,
    default: false,
  },
  label: {
    type: String,
    default: '',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});

defineEmits<{
  (e: 'update:modelValue', value: boolean): void;
}>();

const elementId = `v-checkbox-${Math.random().toString(36).slice(2, 7)}`;
</script>

<template>
  <label
    :for="elementId"
    class="v-checkbox"
    :class="[
      $attrs.class,
      props.disabled && 'is-disabled',
      props.modelValue && 'is-checked',
    ]"
  >
    <input
      v-bind="{ ...$attrs }"
      :id="elementId"
      :checked="modelValue"
      class="v-checkbox__native"
      type="checkbox"
      @change="$emit('update:modelValue', $event.target.checked)"
    />
    <div class="v-checkbox__body">
      <!--      <i class="a-checkbox-icon scale-0 text-white" :class="_icon" />-->
      <IconsCheck class="v-checkbox__arrow" />
    </div>
    <slot>{{ props.label }}</slot>
  </label>
</template>

<style lang="scss">
.v-checkbox {
  display: flex;
  align-items: center;
  outline: none;
  user-select: none;
  cursor: pointer;

  font-size: 1.3rem;
  font-weight: 500;
  line-height: 1.6rem;

  &__body {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: 2.4rem;
    height: 2.4rem;
    margin-right: 1rem;
    border: 2px solid transparent;
    border-radius: 0.4rem;
    background-color: $base-0;
    transition: background-color $base-transition, border-color $base-transition;
  }

  &__arrow {
    width: 100%;
    height: 100%;
    color: $base-0;
    transform: scale(0);
    transition: transform $base-transition;
  }

  &__native {
    display: none;
  }

  &.is-disabled {
    pointer-events: none;
  }

  &.is-checked {
    .v-checkbox__body {
      background-color: $brand-green;
      border-color: $brand-green;
    }

    .v-checkbox__arrow {
      transform: scale(1);
    }
  }
}
</style>
