export function getBaseUrl() {
  const { $i18n } = useNuxtApp();
  const config = useRuntimeConfig();
  const { locale } = $i18n;
  const apiBase = config.public.apiBase;
  return locale.value === 'ru' ? apiBase : `${apiBase}/en`;
}

export function localeUrl(url: string): string {
  if (!url.includes('/api')) {
    return url;
  }
  const { $i18n } = useNuxtApp();
  const { locale } = $i18n;
  if (locale.value === 'en') {
    return url.replace('/api', '/en/api');
  }
  return url;
}
