<script lang="ts" setup>
import { defu } from 'defu';
import type { ExtractPropTypes } from 'vue';
import { baseInputProps } from '~/components/ui/Input/props';
import VBaseInput from '~/components/ui/Input/VBaseInput.vue';

const props = defineProps(
  defu(
    {
      modelValue: [String, Number],
      mask: String,
    },
    baseInputProps
  )
);

const emit = defineEmits<{
  (
    e: 'update:modelValue',
    value: ExtractPropTypes<typeof props>['modelValue']
  ): void;
  (e: 'click:innerIcon'): void;
}>();

const _baseInputProps = reactivePick(
  props,
  Object.keys(baseInputProps) as Array<keyof typeof baseInputProps>
);

const attrs = useAttrs();
const input = ref<HTMLInputElement>();
const isInputTypeFile = attrs.type && attrs.type === 'file';
const handleInputWrapperClick = () => {
  input.value?.focus();
};
defineExpose({
  input,
});
const lazyValue = ref(props.modelValue);

const currentMask = computed(() => {
  switch (props.mask) {
    case 'card':
      return '#### #### #### #### ####';
    default:
      return null;
  }
});
const onInput = (event: InputEvent) => {
  // emit("update:modelValue", (event.target as HTMLInputElement).value);
  if (props.mask && currentMask.value) {
    const target = event.target as HTMLInputElement;
    let endPos = target.selectionEnd || 0;
    const oldValue = lazyValue.value;
    // if (!currentMask.value) {
    //   lazyValue.value = target.value;
    //   return;
    // }
    const newValue = addMask(lazyValue.value.toString(), currentMask.value);
    endPos = getNextCursorPosition(endPos, oldValue.toString(), newValue, ' ');
    lazyValue.value = newValue;
    nextTick(() => {
      setSelection(target, endPos);
    });
  }
  emit('update:modelValue', lazyValue.value);
};
</script>

<template>
  <VBaseInput
    v-bind="{ ..._baseInputProps, class: $attrs.class }"
    :class="[
      isInputTypeFile && 'v-input__type-file',
      `v-input--${props.color}`,
      `v-input--${props.size}`,
    ]"
    class="v-input"
    @click:input-wrapper="handleInputWrapperClick"
    @click:inner-icon="$emit('click:innerIcon')"
  >
    <template
      v-for="name in Object.keys($slots).filter(
        (slotName) => slotName !== 'default'
      )"
      #[name]="slotProps"
    >
      <slot :name="name" v-bind="slotProps || {}" />
    </template>
    <template #default="slotProps">
      <input
        v-bind="{ ...$attrs, ...slotProps }"
        ref="input"
        v-model="lazyValue"
        class="v-input-input"
        @input="onInput"
      />
    </template>
  </VBaseInput>
</template>
