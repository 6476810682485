<template>
    <transition
        name="form-fade"
        @after-enter="$emit('after-enter')"
        @after-leave="$emit('after-leave')"
    >
        <div v-if="visible" :class="[$style.BaseModal, {[$style._login]: login}]">
            <div :class="$style.close">
                <IconsClose name="cross" :class="$style.icon" @click="onCloseClick" />
            </div>
            <div :class="$style.content">
                <slot />
            </div>
        </div>
    </transition>
</template>

<script setup>
defineProps({
    login: Boolean,
    visible: Boolean,
});
const emit = defineEmits(['close', 'after-leave', 'after-enter']);

function onCloseClick() {
    emit('close');
}

onMounted(() => {
    window.addEventListener('keydown', e => {
        if (e.key === 'Escape') {
            emit('close');
        }
    });
});

</script>

<style lang="scss" module>
.BaseModal {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 704px;
    padding: 4.2rem 6rem;
    background: linear-gradient(90deg, #223540 0%, #1D2D36 100%);
    border-radius: 30px;
    transform: translate(-50%, -50%);

    &._login {
        width: 458px;
        padding: 3.3rem 4rem;

        @include respond-to(xs) {
            width: 90%;
            padding: 32px 34px;
            border-radius: 20px;
        }
    }

    @include respond-to(xs) {
        width: 90%;
        padding: 32px 32px 32px 34px;
        border-radius: 20px;
    }

    &:global(.modal-appear-enter-active) {
        transition: transform .4s;
    }

    &:global(.modal-appear-leave-active) {
        transition: transform .2s;
    }

    &:global(.modal-appear-enter-from),
    &:global(.modal-appear-leave-to) {
        transform: translateX(110%);
    }

    &:global(._to-left) {
        right: auto;
        left: 0;

        &:global(.modal-appear-enter),
        &:global(.modal-appear-leave-to) {
            transform: translateX(-110%);
        }
    }
}

.close {
    position: absolute;
    top: 2.4rem;
    right: 2.8rem;
    width: 2rem;
    height: 2rem;

    @include respond-to(xs) {
        top: 19px;
        right: 21px;
    }

    svg {
        width: 100%;
        height: 100%;
    }

    &:hover {
        cursor: pointer;
        color: $brand-green;
    }
}
</style>
