import { defineStore } from 'pinia';
import router from '#app/plugins/router';
import { userApi } from '~/utils/api/user';

const namespace = 'userStore';

export const useUserStore = defineStore(namespace, () => {
  const token = ref('');

  const setToken = (value: string) => {
    token.value = value;
  };

  const removeToken = () => {
    token.value = '';
  };

  const user = ref(null);

  const setUser = (value: {}) => {
    user.value = value;
  };

  const removeUser = () => {
    user.value = null;
  };

  async function loadUser() {
    const { data, error } = await userApi.fetchUser();
    if (error.value) {
      logout();
      return;
    }
    setUser(data.value);
  }

  const isLogged = computed(() => !!token.value);

  const isActivated = computed(() => user?.value?.activated);

  async function logout() {
    removeToken();
    removeUser();
    await userApi.logout();

    const localePath = useLocalePath();
    navigateTo(localePath('/'), { replace: true });
  }

  async function revalidateToken() {
    const { data, error } = await userApi.refreshToken();
    if (error.value) {
      logout();
      return;
    }
    setToken(data.value.access);
  }

  return {
    token,
    setToken,
    removeToken,
    user,
    setUser,
    removeUser,
    loadUser,
    isLogged,
    logout,
    isActivated,
    revalidateToken,
  };
});
