<template>
  <VInput
    :model-value="modelValue"
    :class="$style.InputPassword"
    :append-inner-icon="type === 'text' ? IconsEyeOff : IconsEye"
    :type="type"
    :error="error"
    :placeholder="placeholder"
    :required="required"
    @click:inner-icon="onInnerIconClick"
    @input="onInput"
  />
</template>

<script setup lang="ts">
import VInput from '@/components/ui/Input/VInput.vue';
import IconsEye from '~/components/Icons/IconsEye.vue';
import IconsEyeOff from '~/components/Icons/IconsEyeOff.vue';

defineProps({
  modelValue: {
    type: String,
    default: '',
  },
  placeholder: {
    type: String,
    default: '',
  },
  error: {
    type: String,
    default: '',
  },
  required: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(['update:modelValue', 'input']);

const type = ref('password');

function onInnerIconClick() {
  type.value = type.value === 'password' ? 'text' : 'password';
}

function onInput(value: string) {
  emit('update:modelValue', value.target.value);
  emit('input');
}
</script>

<style lang="scss" module>
.InputPassword {
  //
}
</style>
