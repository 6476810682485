<template>
  <BaseModal
    :class="$style.ForgetModal"
    login
    :visible="visible"
    @after-enter="$emit('after-enter')"
    @after-leave="$emit('after-leave')"
    @close="$emit('close')"
  >
    <div :class="$style.title">
      {{ $t('password recovery') }}
    </div>
    <transition name="fade">
      <p v-if="success" :class="$style.success">
        {{ $t('the application was successfully accepted') }}
      </p>
    </transition>
    <form
      :class="$style.form"
      @submit.prevent="handleSubmit"
      @keydown.enter.prevent="handleSubmit"
    >
      <div :class="$style.input">
        <VInput
          v-model="email"
          placeholder="E-mail"
          type="email"
          required
          :error="errorMsg"
          @input="errorMsg = ''"
        />
      </div>
      <div :class="$style.button">
        <UiVButton
          size="large"
          color="primary"
          responsive
          type="submit"
          spinner
          :loading="isLoading"
        >
          {{ $t('recover') }}
        </UiVButton>
      </div>
    </form>

    <p :class="$style.adv">
      {{ $t('by clicking on the restore button, i agree with') }}
      <NuxtLink :to="localePath('/rules')" :class="$style.link">{{
        $t('the exchange rules')
      }}</NuxtLink>
    </p>
  </BaseModal>
</template>

<script setup>
import BaseModal from '~/components/common/BaseModal.vue';
import VInput from '~/components/ui/Input/VInput.vue';
import { userApi } from '~/utils/api/user';

const email = ref('');
const errorMsg = ref('');
const isLoading = ref(false);
const success = ref(false);

const emit = defineEmits(['close', 'after-enter', 'after-leave']);
defineProps({
  visible: {
    type: Boolean,
    default: false,
  },
});

async function handleSubmit() {
  if (isLoading.value) {
    return;
  }

  try {
    isLoading.value = true;
    const response = await userApi.restore(email.value);
    console.log(response);
    success.value = true;
  } catch (error) {
    error?.data
      ? (errorMsg.value = error.data?.email[0])
      : console.warn(
          '[RegisterForm/handleSubmit] POST request failed: ',
          error
        );
  } finally {
    isLoading.value = false;
  }
}

watch(success, (val) => {
  if (val) {
    setTimeout(() => {
      success.value = false;
    }, 5000);
  }
});
</script>

<style lang="scss" module>
.ForgetModal {
  //
}

.title {
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  color: $base-100;
}

.form {
  margin-top: 22px;

  @include respond-to(xs) {
    margin-top: 25px;
  }
}

.input {
  &:not(:last-child) {
    margin-bottom: 13px;

    @include respond-to(xs) {
      margin-bottom: 11px;
    }
  }
}

.button {
  margin-top: 18px;
}

.adv {
  margin-top: 2rem;
  text-align: center;
  width: 100%;
}

.link {
  font-weight: 600;

  &:hover {
    text-decoration: underline;
  }
}

.error {
  font-size: 14px;
  color: $error-color;
}

.success {
  margin-top: 2rem;
  color: $brand-green;
}
</style>
