// eslint-disable-next-line import/no-named-as-default
import Swiper, {
  Navigation,
  Scrollbar,
  Thumbs,
  Mousewheel,
  Autoplay,
  Pagination,
  FreeMode,
} from 'swiper';
import 'swiper/swiper-bundle.css';

export default defineNuxtPlugin((nuxtApp) => {
  Swiper.use([
    Navigation,
    Scrollbar,
    Thumbs,
    Mousewheel,
    Autoplay,
    Pagination,
    FreeMode,
  ]);
  nuxtApp.provide('Swiper', Swiper);
});
