<template>
  <AsideModalWrapper
    :visible="visible"
    @after-enter="$emit('after-enter')"
    @after-leave="$emit('after-leave')"
    @close="$emit('close')"
  >
    <div :class="$style.MainMenuModal">
      <div :class="$style.close" @click="$emit('close')">
        <!--                <nuxt-icon name="cross" />-->
        <IconsClose :class="$style.iconClose" />
      </div>
      <NuxtLink :to="localePath('/')" :class="$style.logo">
        <CommonTheLogo />
      </NuxtLink>

      <nav :class="$style.nav">
        <ul :class="$style.navList">
          <li
            :class="[
              $style.navItem,
              { [$style._active]: route.path === localePath('/rules') },
            ]"
          >
            <NuxtLink
              :to="localePath('/rules')"
              :class="$style.navLink"
              @click="close"
            >
              {{ $t('rules') }}
            </NuxtLink>
          </li>
          <li
            :class="[
              $style.navItem,
              { [$style._active]: route.path === localePath('/partners') },
            ]"
          >
            <NuxtLink
              :to="localePath('/partners')"
              :class="$style.navLink"
              @click="close"
            >
              {{ $t('partners') }}
            </NuxtLink>
          </li>
          <li
            :class="[
              $style.navItem,
              { [$style._active]: route.path === localePath('/aml') },
            ]"
          >
            <NuxtLink :to="localePath('/aml')" :class="$style.navLink">
              AML/KYC
            </NuxtLink>
          </li>
          <li
            :class="[
              $style.navItem,
              { [$style._active]: route.path === localePath('/faq') },
            ]"
          >
            <NuxtLink :to="localePath('/faq')" :class="$style.navLink"
              >FAQ</NuxtLink
            >
          </li>
          <li
            :class="[
              $style.navItem,
              { [$style._active]: route.path === localePath('/blog') },
            ]"
          >
            <NuxtLink
              :to="localePath('/blog')"
              :class="$style.navLink"
              @click="close"
            >
              {{ $t('blog') }}
            </NuxtLink>
          </li>
          <li
            :class="[
              $style.navItem,
              { [$style._active]: route.path === localePath('/contacts') },
            ]"
          >
            <NuxtLink
              :to="localePath('/contacts')"
              :class="$style.navLink"
              @click="close"
            >
              {{ $t('contacts') }}
            </NuxtLink>
          </li>
        </ul>
      </nav>

      <div :class="$style.profile">
        <div v-if="!isLogged" :class="$style.login" @click="onLoginClick">
          <!--                        <nuxt-icon name="signin" />-->
          <IconsSign :class="$style.icon" />
          <div :class="$style.value">{{ $t('login') }}</div>
        </div>
        <div v-if="!isLogged" :class="$style.login" @click="onRegisterClick">
          <!--                        <nuxt-icon name="signin" />-->
          <IconsUser :class="$style.icon" />
          <div :class="$style.value">{{ $t('registration') }}</div>
        </div>
        <div v-if="isLogged" :class="$style.login">
          <!--                        <nuxt-icon name="signin" />-->
          <IconsUser :class="$style.icon" />
          <NuxtLink :to="localePath('/account')" :class="$style.value">
            {{ $t('personal account') }}
          </NuxtLink>
        </div>
      </div>
    </div>
  </AsideModalWrapper>
</template>

<script setup>
import { storeToRefs } from 'pinia';
import AsideModalWrapper from '@/components/common/AsideModalWrapper';
import LoginModal from '~/components/common/LoginModal.vue';
import RegisterModal from '~/components/common/RegisterModal.vue';
import { useUserStore } from '~/stores/useUserStore';

const emit = defineEmits(['after-enter', 'after-leave', 'close']);
defineProps({
  visible: Boolean,
});

const route = useRoute();
const { isLogged } = storeToRefs(useUserStore());
const { $modal } = useNuxtApp();
const localePath = useLocalePath();

function onLoginClick() {
  $modal.open(markRaw(LoginModal));
}

function onRegisterClick() {
  $modal.open(markRaw(RegisterModal));
}

function close() {
  emit('close');
}
</script>

<style lang="scss" module>
.MainMenuModal {
  position: relative;
  display: flex;
  flex-direction: column;
  width: calc(100vw - 16px);
  height: 100%;
  padding: 32px 46px;
  border-radius: 15px 0 0 15px;
  background: linear-gradient(90deg, #223540 0%, #1d2d36 100%);
}

.close {
  position: absolute;
  top: 21px;
  right: 29px;
  font-size: 25px;
  color: #bdbdbd;
}

.iconClose {
  width: 2.5rem;
  height: 2.5rem;
}

.navItem {
  font-size: 26px;
  font-weight: 400;
  line-height: 30px;
  color: $base-100;

  &:not(:last-child) {
    margin-bottom: 32px;
  }

  &._active {
    color: $brand-green;
  }
}

.nav {
  margin-top: 24px;
  order: 1;
}

.profile {
  margin-top: 48px;
  padding-bottom: 27px;
  border-bottom: 2px solid rgba(#c2c2c2, 0.3);
}

.login {
  display: flex;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 20px;
  }
}

.icon {
  width: 2.1rem;
  height: 2.1rem;
  color: $brand-green;
  margin-right: 12px;
}

.value {
  font-size: 26px;
  font-weight: 400;
  line-height: 30px;
  color: $base-100;
}

.logo {
  display: inline-flex;
  flex-shrink: 0;
  max-width: 100%;

  svg {
    width: 175px;
    max-width: 100%;
    height: 39px;
  }

  @include respond-to(xs) {
    svg {
      width: 135px;
      height: 30px;
    }
  }
}
</style>
