import { userApi } from '~/utils/api/user';
import { useUserStore } from '~/stores/useUserStore';
import ActivateModal from '~/components/common/ActivateModal.vue';

export default function useAuth() {
  const email = ref('');
  const password = ref('');
  const error = ref('');
  const { setToken, loadUser } = useUserStore();
  const localePath = useLocalePath();

  const signIn = async () => {
    try {
      const { access } = await userApi.signIn({
        email: email.value,
        password: password.value,
      });
      setToken(access);
      loadUser().then(() => {
        navigateTo(localePath('/account'));
      });
    } catch (e: any) {
      error.value = e.data.data;
    }
  };

  const activate = async (uuid: string) => {
    try {
      await userApi.activate(uuid);
      const { $modal } = useNuxtApp();
      $modal.open(markRaw(ActivateModal));
    } catch (e: any) {
      console.log(e);
    }
  };

  return {
    email,
    password,
    signIn,
    error,
    activate,
  };
}
