import { useCalculatorStore } from '~/stores/useCalculatorStore';
import { apiFetch } from '~/utils/fetch/fetchApi';
import { calculatorApi } from '~/utils/api/calculator';

export default function useDirectionsCalculator() {
  const store = useCalculatorStore();

  const onFromCurrencyChange = async (id: number) => {
    store.setFromCurrency(id);
    const toCurrencies = await apiFetch<number[]>(
      `/api/v1/calculator/from/${id}/`
    );
    if (Array.isArray(toCurrencies)) {
      store.setToCurrencies(toCurrencies);
    }
    if (!toCurrencies.includes(store.toCurrencyId)) {
      store.setToCurrency(toCurrencies[0]);
    }
    await onCurrencyChange();
  };

  const onToCurrencyChange = async (id: number) => {
    store.setToCurrency(id);
    await onCurrencyChange();
  };

  const onCurrenciesChange = async (from: number, to: number) => {
    store.setFromCurrency(from);
    store.setToCurrency(to);
    await onFromCurrencyChange(from);
    // await onCurrencyChange();
  };

  const onCurrencyChange = async () => {
    const from = store.fromCurrencyId;
    const to = store.toCurrencyId;
    if (!from || !to) {
      return;
    }
    const pairData = await calculatorApi.pair(from, to);
    store.setPairData(pairData);
    store.updateData();
  };

  return {
    onFromCurrencyChange,
    onToCurrencyChange,
    onCurrenciesChange,
    onCurrencyChange,
  };
}
