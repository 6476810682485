export default function useHeader() {
    const header = useState('header', () => false);

    const open = () => header.value = true;

    const close = () => header.value = false;

    return {
        header,
        open,
        close,
    };
}
