export default defineNuxtPlugin(nuxtApp => {
    // called right before setting a new locale
    nuxtApp.hook('i18n:beforeLocaleSwitch', ({ oldLocale, newLocale, initialSetup, context }) => {
        if (process.client) {
            window.jivo_destroy();
        }
    });

    // called right after a new locale has been set
    // nuxtApp.hook('i18n:localeSwitched', ({ oldLocale, newLocale }) => {
    //     if (process.client) {
    //         window.jivo_init();
    //     }
    // });
});
