<template>
    <div :class="[$style.TheBurgerMenu, {[$style._opened]: header}]">
        <div :class="$style.close" @click="onCloseClick">
            <!--            <nuxt-icon name="cross" />-->
        </div>
        <div :class="$style.logo">
            <CommonTheLogo />
        </div>

        <nav :class="$style.nav">
            <ul :class="$style.navList">
                <li :class="[$style.navItem, {[$style._active]: route.path === '/rules'}]">
                    <NuxtLink to="/rules" :class="$style.navLink" @click="close">
                        {{
                            $t('rules')
                        }}
                    </NuxtLink>
                </li>
                <li :class="[$style.navItem, {[$style._active]: route.path === '/partners'}]">
                    <NuxtLink to="/partners" :class="$style.navLink" @click="close">
                        {{ $t('partners') }}
                    </NuxtLink>
                </li>
                <li :class="[$style.navItem, {[$style._active]: route.path === localePath('/aml')}]">
                    <NuxtLink :to="localePath('/aml')" :class="$style.navLink">AML/KYC</NuxtLink>
                </li>
                <li :class="[$style.navItem, {[$style._active]: route.path === '/faq'}]">
                    <NuxtLink to="/faq" :class="$style.navLink">FAQ</NuxtLink>
                </li>
                <li :class="[$style.navItem, {[$style._active]: route.path === '/blog'}]">
                    <NuxtLink to="/blog" :class="$style.navLink" @click="close">
                        {{
                            $t('blog')
                        }}
                    </NuxtLink>
                </li>
                <li :class="[$style.navItem, {[$style._active]: route.path === '/contacts'}]">
                    <NuxtLink to="/contacts" :class="$style.navLink" @click="close">
                        {{ $t('contacts') }}
                    </NuxtLink>
                </li>
            </ul>
        </nav>

        <div :class="$style.profile">
            <div :class="$style.login" @click="onLoginClick">
                <div :class="$style.icon">
                    <!--                    <nuxt-icon name="signin" />-->
                </div>
                <div :class="$style.value">{{ $t('login') }}</div>
            </div>
            <div :class="$style.login" @click="onRegisterClick">
                <div :class="$style.icon">
                    <!--                    <nuxt-icon name="signin" />-->
                </div>
                <div :class="$style.value">{{ $t('registration') }}</div>
            </div>
        </div>
        <LayoutHeaderLanguagePicker :class="$style.picker" />
    </div>
</template>

<script setup>
import LoginModal from '~/components/common/LoginModal.vue';
import RegisterModal from '~/components/common/RegisterModal.vue';

const { header, close } = useHeader();
const route = useRoute();

watch(route, () => {
    close();
    unlockBody();
});
const onCloseClick = () => {
    close();
    unlockBody();
};

const { $modal } = useNuxtApp();
const onLoginClick = () => {
    close();
    unlockBody();
    $modal.open({ component: markRaw(LoginModal) });
};

const onRegisterClick = () => {
    close();
    unlockBody();
    $modal.open({ component: markRaw(RegisterModal) });
};

</script>

<style lang="scss" module>
.TheBurgerMenu {
    position: fixed;
    top: 18px;
    left: 16px;
    right: 0;
    bottom: 18px;
    z-index: 100;
    display: flex;
    flex-direction: column;
    width: 100%;
    //height: 100%;
    padding: 32px 46px;
    background: linear-gradient(90deg, #223540 0%, #1D2D36 100%);
    border-radius: 15px 0 0 15px;
    transform: translateX(100%);
    transition: transform .4s;

    &._opened {
        transform: translateX(0);
    }
}

.close {
    position: absolute;
    top: 21px;
    right: 29px;
    font-size: 25px;
    color: #BDBDBD;
}

.navItem {
    font-size: 26px;
    font-weight: 400;
    line-height: 30px;
    color: $base-100;

    &:not(:last-child) {
        margin-bottom: 35px;
    }

    &._active {
        color: $brand-green;
    }
}

.nav {
    margin-top: 48px;
    padding-bottom: 27px;
    border-bottom: 2px solid rgba(#C2C2C2, .3);
}

.profile {
    margin-top: 24px;
}

.login {
    display: flex;
    align-items: center;

    &:not(:last-child) {
        margin-bottom: 20px;
    }
}

.icon {
    font-size: 21px;
    color: $brand-green;
    margin-right: 12px;
}

.value {
    font-size: 26px;
    font-weight: 400;
    line-height: 30px;
    color: $base-100;
}

.logo {
    display: inline-flex;
    flex-shrink: 0;
    max-width: 100%;

    svg {
        width: 175px;
        max-width: 100%;
        height: 39px;
    }

    @include respond-to(xs) {
        svg {
            width: 135px;
            height: 30px;
        }
    }
}

.picker {
    margin-top: auto;
}
</style>
